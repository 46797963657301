<template>
	<v-container
			fluid
	>
		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-data-table
								:loading="loading"
								:headers="headers"
								:items="items"
								:server-items-length="totalItems"
								:options.sync="options"
								item-key="id"
								class="row-pointer"

								@click:row="goToContent"
								:footer-props="{'items-per-page-options':[10,25,50]}"
						>
							<template v-slot:[`item.datetime`]="{ item }">
								<span class="text-no-wrap">{{ dayjs(item.datetime).format(`YYYY-MM-DD HH:mm`) }}</span>
							</template>
							<template v-slot:[`item.type`]="{ item }">
								<svg-icon v-if="item.entity.type === `movement` && item.entity.movement.type === `idea`" icon="idea" />
								<svg-icon v-else-if="item.entity.type === `movement` && item.entity.movement.type === `love`" icon="love" />
								<svg-icon v-else-if="item.entity.type === `movement` && item.entity.movement.type === `bomb`" icon="warning" />
								<svg-icon v-else-if="item.entity.type === `news`" icon="news" />
								<svg-icon v-else-if="item.entity.type === `pledge`" icon="pledge" />
								<svg-icon v-else-if="item.entity.type === `post`" icon="pencil" />
								<svg-icon v-else-if="item.entity_type === `comment`" icon="comment" />
								<span v-else>{{ item.entity.type }}</span>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { globalMixIn } from '@/App'

	export default {
		data: () => ({
			loading: false,
			headers: [
				{ text: ``, value: `type` },
				{ text: `Name`, value: `entity.author_name` },
				//{ text: `Message`, value: `entity.text` },
				{ text: `Title`, value: `entity.title` },
				{ text: `Time`, value: `datetime` },
			],
			options: {
				sortDesc: [true],
				sortBy: [`datetime`],
			},
			totalItems: 0,
			items: [],
		}),
		methods: {
			goToContent(value) {
				if (value.entity_type === `comment`) this.$router.push({name: `Post`, params: {id: value.entity.post_id}, query: {comment: value.entity.id}})
				if (value.entity_type === `post`) this.$router.push({name: `Post`, params: {id: value.entity.id}})
			},
			refresh () {
				this.loading = true
				this.$httpInt.get(`/v2/app/reports`, { params: this.$paginationParams(this) })
					.then(res => {
						this.items = res.data.result
						this.totalItems = res.data.pagination.total
					})
					.finally(() => this.loading = false)
			},
		},
		mixins: [globalMixIn],
		mounted () {
			this.refresh()
		},
		name: `CommunityAbuse`,
		watch: {
			options: {
				handler () {
					this.refresh()
				},
			},
		},
	}
</script>
